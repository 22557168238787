import React from "react";
import Infinity from "../components/shapes/Infinity";

const Spinner = () => (
  <div role="status">
    <Infinity height="48px" className="pathShadow animate-pulse" />
    <span className="sr-only">Loading...</span>
  </div>
)

export default Spinner;