import React, {useMemo} from "react";
import * as d3 from 'd3';
import { FeatureCollection } from 'geojson';
import { isNull } from "lodash";
import { geoData, MapNumDataType, numData } from "../utils/geo";
import {Store, useStore} from "../store";

const BUBBLE_MIN_SIZE = 5;
const BUBBLE_MAX_SIZE = 25;
const OPACITY_MIN_SIZE = 0.2;
const OPACITY_MAX_SIZE = 0.8;
const COLOR = '#ffffff';

type MapProps = {
  width: number;
  height: number;
  // geoData: FeatureCollection;
  // numData: { name: string; code: string; value: number }[];
  userCountryCode?: string;
};

const Map = ({ width, height, userCountryCode }: MapProps) => {
  const appData = useStore((state: Store) => state.appData)
  const stringifiedActiveUsersPerCountry = JSON.stringify(appData.activeUsersPerCountry);

  const updatedNumData = useMemo(
    () => {
      const newNumData = JSON.parse(JSON.stringify(numData)) as MapNumDataType[];
      JSON.parse(stringifiedActiveUsersPerCountry).forEach((countryActiveUsers: { country: string, activeUsers: number}) => {
        const element = newNumData.find((d) => d.name === countryActiveUsers.country)
        if (element) {
          element.value = Number(countryActiveUsers.activeUsers)
        }
      })
      return newNumData;
    },
    [stringifiedActiveUsersPerCountry]
  )

  const userRegionGeoData = geoData.features.find(
    (geoRegion: any) => geoRegion.properties.name === userCountryCode
  );

  const projection = d3
    .geoMercator()
    .scale(width / 2.3 / Math.PI)
    .center([325, -72]);

  const geoPathGenerator = d3.geoPath().projection(projection);

   // Bubble Size scale uses scaleSqrt to have the area proportional to the value
   const [min, max] = d3.extent(updatedNumData.map((d) => d.value)) as [number, number];
   const sizeScale = d3
     .scaleSqrt()
     .domain([min, max])
     .range([BUBBLE_MIN_SIZE, BUBBLE_MAX_SIZE]);

   const opacityScale = d3
     .scaleSqrt()
     .domain([min, max])
     .range([OPACITY_MIN_SIZE, OPACITY_MAX_SIZE]);

  const countryColours = {} as { [key: string]: number }
  updatedNumData.forEach(region => {
    const regionGeoData = geoData.features.find(
      (geoRegion: any) => geoRegion.id === region.code
    );
    if (regionGeoData && regionGeoData.id && region.value > 0) {
      countryColours[String(regionGeoData.id)] = opacityScale(region.value);
    }
  })

  const allCountryPaths = geoData.features
    .filter((shape: any) => shape.id !== 'ATA')
    .map((shape: any) => {
      return (
        <path
          key={shape.id}
          className="transition-[fill-opacity] duration-1000"
          d={geoPathGenerator(shape) || undefined}
          fill="white"
          fillOpacity={countryColours[String(shape.id)] || 0.1}
        />
      );
    });

  const allCircleShapes = updatedNumData
    .sort((a, b) => b.value - a.value)
    .map((region, i) => {
      // Find the corresponding geoData information
      const regionGeoData = geoData.features.find(
        (geoRegion: any) => geoRegion.id === region.code
      );
      if (!regionGeoData|| region.value === 0) {
        return <circle key={i} />;
      }

      // Find the centroid of the region
      const centroid = geoPathGenerator.centroid(regionGeoData);

      // Draw a circle
      return (
        <circle
          key={i}
          r={sizeScale(region.value)}
          cx={centroid[0]}
          cy={centroid[1]}
          opacity={1}
          // stroke={COLOR}
          // fill={COLOR}
          fill="url('#myGradient')"
          fillOpacity={0.5}
          // strokeWidth={1}
        />
      );
    })
    .filter(shape => !isNull(shape));

  return (
    <svg width={width} height={height}>
      <defs>
        <radialGradient id="myGradient">
          <stop offset="30%" stopColor="white" />
          <stop offset="100%" stopColor="white" stopOpacity={0} />
        </radialGradient>
      </defs>
      {allCountryPaths}
      {/*{allCircleShapes}*/}
    </svg>
  );
};

export default Map;