import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { HiOutlineLanguage } from "react-icons/hi2";
import { API_URL } from "../utils/consts";
import { Store, useStore } from "../store";

const Language = () => {
  const { i18n } = useTranslation()

  const userData = useStore((state: Store) => state.userData)
  const setUserData = useStore((state: Store) => state.setUserData)
  const [open, setOpen] = useState(false);
  const { language } = userData;

  const handleClick = (language: string) => {
    if (open) {
      setUserData({ ...userData, language })
      i18n.changeLanguage(language);
      axios.patch(`${API_URL}/users/${userData.id}/language`, { language })
        .catch((err) => console.log(err))
    }
    setOpen(prev => !prev)
  }

  const mapping = {
    "en": { "src": '', name: "English", code: 'EN' },
    "es": { "src": '', name: "Spanish", code: 'ES' },
    "fr": { "src": '', name: "French", code: 'FR' },
    "pl": { "src": '', name: "Polish", code: 'PL' },
  } as { [key: string]: { src: string, name: string, code: string } }

  return(
    <div className="overflow-hidden flex items-center flex-col transition-all duration-500" style={{height: open ? 38 * 4 : 38, color: 'rgba(255,255,255,0.5)'}}>
      {["en", "es", "fr", "pl"].map(lang =>
        <button
          key={lang}
          onClick={() => handleClick(lang)}
          className="flex justify-center items-center"
          style={{
            order: language === lang ? -1 : 0,
            opacity: open || language === lang ? "100%" : "0%",
            visibility: open || language === lang ? "visible" : "hidden"
          }}
        >
          <HiOutlineLanguage size={20} style={{ marginRight: "8px", visibility: language === lang ? "visible" : "hidden" }} />
          {mapping[lang].name}
        </button>
      )}
    </div>
  )
}

export default Language;