import { create } from 'zustand'
import axios from "axios";
import { API_URL } from "./utils/consts";

type Intention = {
  id: number;
  text: string;
}

type AppData = {
  totalUsers: number;
  activeUsers: number;
  totalCountries: number;
  activeUsersPerCountry: { country: string, activeUsers: number}[];
  intention: Intention;
}

type UserIntention = {
  id: number;
  intentionId: number;
  userId: string;
  startTime: Date;
  endTime: Date;
  autoFinished: boolean;
}

type UserData = {
  id: string | undefined;
  name: string | undefined;
  email: string | undefined;
  country: string | undefined;
  city: string | undefined;
  language: string | undefined;
  introCompleted: boolean;
  userIntentions: UserIntention[];
}

type State = {
  userData: UserData;
  appData: AppData;
}

type Action = {
  setUserData: (userData: State['userData']) => void;
  setAppData: (data: State['appData']) => void;
  fetchAppData: () => void;
}

export type Store = State & Action;

export const useStore = create<Store>((set) => ({
  userData: {
    id: undefined,
    name: undefined,
    email: undefined,
    country: undefined,
    city: undefined,
    language: undefined,
    introCompleted: false,
    userIntentions: [],
  },
  appData: {
    totalUsers: 0,
    activeUsers: 0,
    totalCountries: 0,
    activeUsersPerCountry: [],
    intention: { id: 0, text: '' },
  },
  setUserData: (userData) => set(() => ({ userData })),
  setAppData: (appData) => set(() => ({ appData } )),
  fetchAppData: () => axios.get(`${API_URL}/data`)
    .then(response => { set({ appData: response.data }) })
    .catch((err) => console.log(err))
}))