import React, {FC, useState} from "react";
import { useTranslation } from "react-i18next";
import {numData} from "../utils/geo";
import {Store, useStore} from "../store";
import axios from "axios";
import {API_URL} from "../utils/consts";

const Settings: FC<{}> = () => {
  const { t, i18n } = useTranslation()

  const userData = useStore((state: Store) => state.userData)
  const setUserData = useStore((state: Store) => state.setUserData)
  const { country } = userData;

  const [open, setOpen] = useState(false);

  const handleCountryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCountry = event.target.value
    setUserData({ ...userData, country: selectedCountry })

    axios.patch(`${API_URL}/users/${userData.id}/country`, { country: selectedCountry })
      .catch((err) => console.log(err))
  };

  return(
    <div className="z-1 w-[100vw] transition-[height] duration-50" style={{ height: open ? "100vh" : "24px"}}>
      <div className="flex justify-end">
        <button onClick={() => setOpen(prev => !prev)}>
          {open ? 'close' : 'open'}
        </button>
      </div>
      {open && (
        <div className="h-full w-full flex flex-col justify-center items-center background-app">
          <h1 className="my-16 uppercase">
            Settings
          </h1>

          <div className="flex-1 flex-col justify-center content-center">

            {/*country*/}
            <div>
              <label htmlFor="country-select" className="block mb-2">
                Where are you located?
              </label>
              <select
                id="country-select"
                name="country"
                value={country || ""}
                onChange={handleCountryChange}
                className="z-10 px-4 block w-[250px] bg-transparent border border-white text-white text-center rounded-full"
                required
              >
                <option value="" disabled hidden>Where are you located?</option>
                {numData.sort((a,b) => a.name.localeCompare(b.name)).map(country =>
                  <option key={country.code} value={country.name}>{country.name}</option>
                )}
              </select>
            </div>
          </div>
        </div>
      )}
    </div>
  )
};

export default Settings;