import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Store, useStore } from "../../store";
import { API_URL } from "../../utils/consts";
import Spinner from "../../utils/Spinner";

type SummaryData = {
  meditationTime: number;
  otherUsersCount: number;
  otherUsersTime: number;
  daysStrike: number;
}

const Summary = () => {
  let [searchParams] = useSearchParams();
  let id = searchParams.get("id");

  const userData = useStore((state: Store) => state.userData)
  const fetchAppData = useStore((state: Store) => state.fetchAppData)

  const [summaryData, setSummaryData] = useState<SummaryData | undefined>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get(`${API_URL}/user_intentions/${id}/summary?user_id=${userData.id}`)
      .then(response => setSummaryData(response.data))
      .catch((err) => console.log(err))
      .finally(() => { setLoading(false) })
  }, [id, userData.id]);

  if (loading) {
    return(
      <div className="page flex-1 justify-center items-center">
        <Spinner />
      </div>
    )
  }

  if (!summaryData) {
    return (
      <div className="page flex-1 justify-center items-center">
        <p>Something wen't wrong :(</p>
      </div>
    )
  }

  const meditationTimeDuration = moment.duration(summaryData.meditationTime, 'seconds')
  const summedMeditationTimeDuration = moment.duration(summaryData.meditationTime + summaryData.otherUsersTime, 'seconds')

  return (
    <div className="page justify-center">
      <p className="text-3xl" style={{ fontFamily: "Sacramento"}}>Thank you!</p>

      <div className="my-16">
        <p>
          You've been reflecting in intention for {meditationTimeDuration.minutes()} minutes {meditationTimeDuration.seconds()} seconds
        </p>

        {summaryData.otherUsersCount > 0 &&(
          <>
            <p className="mt-2 mb-2">In that time there were {summaryData.otherUsersCount} users meditating together with you.</p>
            <p>You've all been meditating in total for {summedMeditationTimeDuration.minutes()} minutes {summedMeditationTimeDuration.seconds()} seconds!</p>
          </>
        )}
      </div>

      <Link to="/">
        <button className="mt-4 cta secondary">
          Home
        </button>
      </Link>
    </div>
  );
};

export default Summary;