import React, { FC, MouseEventHandler } from "react";
import IntroPage from "./IntroPage";
import { useTranslation } from "react-i18next";
import Infinity from "../../components/shapes/Infinity"

const Step1: FC<{ onNext: MouseEventHandler<HTMLButtonElement>}> = ({ onNext }) => {
  const { t, i18n } = useTranslation()

  return(
    <IntroPage title="How It Works">
      <p className="text-center italic text-3xl" style={{ fontFamily: "Sacramento" }}>
        Dive in
      </p>

      <Infinity height="100px" className="pathShadow" />

      <p className="text-center mb-8">
        Each day, we offer a new intention to reflect on.
      </p>
      <p className="text-center mb-16">
        An audio guide or a subtle background track can help you to immerse in a contemplative meditation.
      </p>
      <button onClick={onNext} className="cta secondary">
        Next
      </button>
    </IntroPage>
  )
};

export default Step1;