import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { isNull } from "lodash";
import { useTranslation } from 'react-i18next'
import { API_URL } from "../../utils/consts";
import { Store, useStore } from "../../store";
import Stats from "../../stats";
import Map from "../../stats/Map";
import Intro from "../intro";
import Settings from "../../components/Settings";

const poolingInterval = 10000;

const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const appData = useStore((state: Store) => state.appData)
  const userData = useStore((state: Store) => state.userData)
  const setAppData = useStore((state: Store) => state.setAppData)
  const setUserData = useStore((state: Store) => state.setUserData)
  const fetchAppData = useStore((state: Store) => state.fetchAppData)

  //TODO
  const height = 667;
  const width = 375;

  useEffect(() => {
    if (userData.introCompleted) {
      fetchAppData();
      const intervalId = setInterval(fetchAppData, poolingInterval);

      return () => clearInterval(intervalId);
    }
  }, [userData.introCompleted, fetchAppData])

  const intentionInProgress = useMemo(
    () => userData.userIntentions.find(ui => isNull(ui.endTime)),
    [userData.userIntentions]
  );

  const join = () => axios.post(`${API_URL}/user_intentions/start`, { user_id: userData.id, intention_id: appData.intention.id })
    .then(response => {
      setAppData({...appData, activeUsers: appData.activeUsers + 1})
      setUserData({ ...userData, userIntentions: [...userData.userIntentions, response.data] })
      fetchAppData();
    })
    .catch(err => console.log(err));

  const finish = () => {
    if (!intentionInProgress) {
      return
    }

    axios.patch(`${API_URL}/user_intentions/${intentionInProgress.id}/finish`, { user_id: userData.id  })
      .then(response => {
        setAppData({ ...appData, activeUsers: appData.activeUsers - 1 })
        const finishedIntention = response.data;
        const intentionIndex = userData.userIntentions.findIndex(i => i.id === finishedIntention.id)
        const userIntentions = [userData.userIntentions.slice(0, intentionIndex), finishedIntention, userData.userIntentions.slice(intentionIndex + 1)];
        setUserData({ ...userData, userIntentions })
        navigate(`/summary?id=${finishedIntention.id}`);
      })
      .catch(err => console.log(err));
  }

  //TODO move to app?
  if(userData && !userData.introCompleted) {
    return <Intro />
  }

  return(
    <div className="page justify-between flex-1">
      <div className="absolute left-0 top-0">
        <Settings />
      </div>

      <div className="flex flex-col justify-evenly items-center h-full">
        {!appData.intention && (
          <p className="mt-4 text-lg">
            We’re sorry, there is no intention for today. Just take a moment to reflect and be grateful.
          </p>
        )}
        {appData.intention && (
          <div className="mt-4">
            <p className="text-sm uppercase secondary">
              {t('home.intentionHeader')}
            </p>
            <p className="mt-4 text-3xl" style={{ fontFamily: "Sacramento"}}>
              {appData.intention.text}
            </p>
            <button
              onClick={() => (intentionInProgress ? finish() : join())}
              className="cta primary mt-8"
            >
              {intentionInProgress ? 'Finish' : t('home.join')}
            </button>
          </div>
        )}

        <div className="w-full h-[275px]">
          <div className="my-8 flex justify-center overflow-hidden transition-[height] duration-500" style={{ height: intentionInProgress ? 0 : "275px" }}>
            <Map
              userCountryCode={userData.country}
              width={width}
              height={width * (width/height)}
            />
          </div>
          <div className="my-8 flex justify-center overflow-hidden transition-[height] duration-500" style={{ height:   intentionInProgress ? "275px" : 0 }}>
            <div>
              [Music] [Guide]
              Breathe
            </div>
          </div>
        </div>
      </div>

      <div className="w-full px-2">
       <Stats appData={appData} />
      </div>
    </div>
  );
}

export default Home;