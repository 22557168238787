import axios from "axios";
import { set, get } from 'idb-keyval';
import { API_URL } from "./consts";

export const getOrInitializeUser = async () => {
  const userIdKey = "oraya.userId";
  let userId  = await get(userIdKey);

  if (userId) {
    const response = await axios.get(`${API_URL}/users/${userId}`).catch(error => console.log("Signup error: ", error));
    if (response && response.status === 200) {
      return response.data;
    }
    //TODO handle error
  }

  try {
    const { data } = await axios.post(`${API_URL}/users/signup`);
    userId = data.id
    await set(userIdKey, userId);
    return data
  } catch (error) {
    // TODO error
    console.log("Signup error: ", error)
    return { id: '' }
  }
}