import React, {FC, PropsWithChildren, useEffect, useState} from "react";
import axios from "axios";
import { Store, useStore } from "../store";
import {API_URL, ENVIRONMENT} from "../utils/consts";
import { getOrInitializeUser } from "../utils/user-manager";
import '../i18n/config';
import Logo from "../assets/logo.svg";

const Splash: FC<PropsWithChildren> = ({ children }) => {
  let demo = window.location.search.includes("demo=true")

  const setAppData = useStore((state: Store) => state.setAppData)
  const setUserData = useStore((state: Store) => state.setUserData)
  const userData = useStore((state: Store) => state.userData)
  const { id: userId } = userData;

  const [stateLoaded, setStateLoaded] = useState(false);
  const [waited, setWaited] = useState(false);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const counting = () => {
      if(counter > 0 && !waited) {
        setWaited(true);
      }
      setCounter(prev => (prev + 1) % 4);
    }
    const intervalId = setInterval(counting, 1500)

    return () => clearInterval(intervalId);
  }, [waited, counter])

  useEffect(() => {
    if (ENVIRONMENT === 'development' || demo) {
      if (!userId) {
        getOrInitializeUser().then(user => setUserData(user));
      }
      axios.get(`${API_URL}/data`).then(response => {
        setAppData(response.data)
      }).catch(e => {
        //TODO handle error
        console.log(e)
      }).finally(() => {
        setStateLoaded(true)
      })
    }
  }, [demo, setAppData, setUserData])

  if(waited && stateLoaded) {
    return <>{children}</>
  }

  return(
    <div className="page justify-center text-white">
      {/*<p className="text-5xl mb-4" style={{ fontFamily: "Sacramento"}}>Oraya</p>*/}
      <img className="mb-4 text-white h-[72px]" src={Logo} alt="Oraya logo" />
      <p className="text-xs text-[rgba(255,255,255,0.6)]">
        Unite in&nbsp;
        <span className={counter === 1 ? "text-white" : ""}>Reflection</span>
        ,&nbsp;
        <span className={counter === 2 ? "text-white" : ""}>Purpose</span>
        ,&nbsp;and&nbsp;
        <span className={counter === 3 ? "text-white" : ""}>Hope</span>
      </p>
      <p className="font-light text-sm mt-16 p-2 text-[rgba(255,255,255,0.6)]">
        COMING SOON...
      </p>
    </div>
  )
}

export default Splash;