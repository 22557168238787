import React, {FC, MouseEventHandler } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { WiStars } from "react-icons/wi";
import { API_URL } from "../../utils/consts";
import { Store, useStore } from "../../store";
import Language from "../../components/Language";
import IntroPage from "./IntroPage";
import { numData } from "../../utils/geo";


const Step1: FC<{ onNext: MouseEventHandler<HTMLButtonElement>}> = ({ onNext }) => {
  const { t, i18n } = useTranslation()

  const userData = useStore((state: Store) => state.userData)
  const setUserData = useStore((state: Store) => state.setUserData)
  const { country } = userData;

  const handleCountryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCountry = event.target.value
    setUserData({ ...userData, country: selectedCountry })

    axios.patch(`${API_URL}/users/${userData.id}/country`, { country: selectedCountry })
      .catch((err) => console.log(err))
  };

  return(
    <div>
      <div className="absolute top-[16px] right-[16px] z-10">
        <Language />
      </div>

      <IntroPage title={t('home.welcome')}>
        <div className="flex flex-col w-full content-center justify-center">
          <p className="text-center text-3xl italic" style={{ fontFamily: "Sacramento"}}>
            Let’s bring light and hope together.
          </p>

          <div className="flex justify-center my-8">
            <WiStars size={64} className="pathShadow" style={{ color: "rgba(255, 255, 255, 0.9)"}} />
            {/*<div style={{ position: "relative", width: "60px", height: "60px", borderRadius: "9999px", boxShadow: "3px -3px 15px 0px #fff" }}>*/}
            {/*</div>*/}
            {/*<div*/}
            {/*  className="w-[1000px] h-[1000px]   rounded-full absolute top-[0px]"*/}
            {/*  style={{ backgroundColor: "rgba(0,0,0,0)", boxShadow: "3px -3px 15px 0px #fff" }}*/}
            {/*/>*/}
          </div>

          <p className="text-center mb-16">
            Every day, unite with people worldwide in a shared intention to uplift and inspire through&nbsp;meditation.
          </p>

          <div className="flex flex-col items-center justify-center">
            <select
              id="country-select"
              name="country"
              value={country || ""}
              onChange={handleCountryChange}
              className="z-10 px-4 block w-[250px] bg-transparent border border-white text-white text-center rounded-full"
              required
            >
              <option value="" disabled hidden>Where are you located?</option>
              {numData.sort((a,b) => a.name.localeCompare(b.name)).map(country =>
                <option key={country.code} value={country.name}>{country.name}</option>
              )}
            </select>

            <button
              onClick={onNext}
              disabled={!country}
              className="cta secondary z-10 mt-4"
            >
              Next
            </button>
          </div>
        </div>
      </IntroPage>
    </div>
  )
};

export default Step1;