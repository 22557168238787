import React from 'react';
import {RouterProvider} from "react-router-dom";
import './App.css';
import './i18n/config';
import router from "./router";
import Splash from "./pages/Splash";
import ErrorBoundary from "./utils/ErrorBoundary";

function App() {
  return (
    <div className="app">
      <ErrorBoundary>
        <Splash>
          <RouterProvider router={router} />
        </Splash>
      </ErrorBoundary>
    </div>
  );
}

export default App;
