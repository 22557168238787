import React, { FC, MouseEventHandler } from "react";
import IntroPage from "./IntroPage";
import { useTranslation } from "react-i18next";
import axios from "axios";
import {API_URL} from "../../utils/consts";
import {Store, useStore} from "../../store";

const Step1: FC<{ onNext: MouseEventHandler<HTMLButtonElement>}> = ({ onNext }) => {
  const { t, i18n } = useTranslation()

  const userData = useStore((state: Store) => state.userData)
  const setUserData = useStore((state: Store) => state.setUserData)

  const start = () => {
    setUserData({ ...userData, introCompleted: true })
    axios.patch(`${API_URL}/users/${userData.id}/complete_intro`)
      .catch((err) => console.log(err))
  };

  return(
    <IntroPage title="Experience">
      <p className="text-center italic text-3xl" style={{ fontFamily: "Sacramento" }}>
        Feel connected to something greater.
      </p>

      {/*<div className="flex w-full justify-center relative h-[100px] my-16">*/}
      {/*  <div className="w-[100px] h-[100px] rounded-full border-white absolute top-[0px] left-[calc(50%-50px)]" style={{ boxShadow: "-3px 1px 5px 1px #fff" }} />*/}
      {/*  <div className="w-[84px] h-[84px] rounded-full border-white absolute top-[8px] left-[calc(50%-50px-30px)]" style={{ boxShadow: "-3px 1px 5px 1px #fff" }} />*/}
      {/*  <div className="w-[68px] h-[68px] rounded-full border-white absolute top-[16px] left-[calc(50%)]" style={{ boxShadow: "-3px 1px 5px 1px #fff" }} />*/}
      {/*  <div className="w-[52px] h-[52px] rounded-full border-white absolute top-[24px] left-[calc(50%-50px+30px)]" style={{ boxShadow: "-3px 1px 5px 1px #fff" }} />*/}
      {/*  <div className="w-[36px] h-[36px] rounded-full border-white absolute top-[32px] left-[calc(50%-50px+60px)]" style={{ boxShadow: "-3px 1px 5px 1px #fff" }} />*/}
      {/*</div>*/}

      <div className="flex flex-col w-full justify-center items-center relative h-[84px] my-16">
        <div className="w-[84px] h-[84px] rounded-full border-white absolute top-[0px] left-[calc(50%-68px)]" style={{ boxShadow: "0px 0px 5px 1px #fff" }} />
        <div className="w-[68px] h-[68px] rounded-full border-white absolute top-[10px] left-[calc(50%-18px)]" style={{ boxShadow: "0px 0px 5px 1px #fff" }} />
      </div>

      <p className="text-center mb-16">
        See in real time how many others are in the intention with you.
      </p>

      <button onClick={start} className="cta secondary">
        Start the Journey
      </button>
    </IntroPage>
  )
};

export default Step1;