import React, { FC, PropsWithChildren } from "react";

const IntroPage: FC<{ title: string } & PropsWithChildren> = ({ children, title }) => (
  <div className=" flex flex-col h-[100vh] justify-center items-center p-2">
    {/*<div className="h-[54px] flex items-center">*/}
    {/*  <h1 className="text-sm text-center uppercase">{title}</h1>*/}
    {/*</div>*/}
    <div className="flex flex-col flex-1 justify-center items-center sm:w-[400px]">
      {children}
    </div>
  </div>
);

export default IntroPage;